






























import { mapActions, mapGetters } from 'vuex'
import ConfirmEmailComponent from '@/components/common/ConfirmEmailComponent.vue'
import SignContainerComponent from '@/components/common/SignContainerComponent/SignContainerComponent.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'

export default {
  name: 'ConfirmEmail',
  components: {
    ConfirmEmailComponent,
    SignContainerComponent,
    MarkdownComponent,
  },
  inject: ['enums'],
  data() {
    return {
      status: this.enums.EmailConfirmationStatus.InProgress,
      email: '',
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      organisationStatus: 'getOrganisationStatus',
    }),
    shouldShowResend() {
      return [
        this.enums.EmailConfirmationStatus.NoParams,
        this.enums.EmailConfirmationStatus.InProgress,
        this.enums.EmailConfirmationStatus.Error,
      ].includes(this.status)
    },
  },
  async created() {
    const { email, code } = this.$route.query

    if (!email || !code) {
      this.status = this.enums.EmailConfirmationStatus.NoParams

      return
    }

    this.email = encodeURIComponent(email)

    try {
      await this.confirmSignUp({ email, code: `${code}` })
      this.status = this.enums.EmailConfirmationStatus.Success
    } catch (error) {
      this.status = this.enums.EmailConfirmationStatus.Error
    }
  },
  methods: {
    ...mapActions('auth', ['confirmSignUp']),
  },
}
